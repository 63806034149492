import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoEN } from "../components/seo";
import { translations } from "../locales/communion.js";
import background from "../assets/img/eventsTypes/communion.svg";
import EventsTypes from "../components/eventsTypes";

const Communion = () => {
  return (
    <Layout page="communion" language="en">
      <main className=" pricing-page-container container">
        <EventsTypes
          background={background}
          text={translations.en.text}
          title={translations.en.title}
          textButton={translations.en.button}
          language={"en"}
        />
      </main>
    </Layout>
  );
};

export default Communion;

export const Head = () => (
  <SeoEN
    title="Digital Invitations for Communion Online | Design your invitations with Kolibird"
    description="Design personalized digital invitations for your children's communion with Kolibird. An ecological and practical alternative to invite your family and friends. Sign up now in Kolibird and start creating your digital communion invitations."
    hrefLangs={[
      {
        href: "https://kolibird.app/invitaciones-digitales-comunion/",
        lang: "es",
      },
      {
        href: "https://kolibird.app/en/digital-communion-invitations/",
        lang: "en",
      },
    ]}
  />
);
